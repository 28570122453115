#AppBar {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
  padding-right: 20px;

  > .ant-menu {
    border-bottom: none;
    flex: 1;
  }
}
.menuIcon {
  margin-left: 12px;
  color: #1b1f4b;
  border: none;
  box-shadow: none;
  font-size: 20px;
  line-height: 22px;
}

.mobileMenuInline {
  flex-grow: 1;
  height: 100%;
}
.Mobile_menu {
  padding-bottom: 84px;
}

.innerDrawer {
  height: 300px;
}

.drawerMobile {
  position: relative;
}

.bottomDrawer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  left: 0;
  box-shadow: 0px -2px 20px #0000001a;
}
.bottomInner {
  padding: 12px 24px;
}

@media (max-width: 980px) {
  .Hide_mobile {
    display: none !important;
  }
}
