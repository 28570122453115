@import '~antd/dist/antd.less';
@import './style/global.less';

.ant-tooltip {
  max-width: 450px;
}

.CardList {
  display: flex;
  align-items: center;
}

#Stats .AppName {
  font-size: 18px;
  padding-left: 16px;
  margin-bottom: -12px;
}
