.PageWrapper {
  padding: 16px;

  .Filters {
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.Workspace_stats_inner {
  display: flex;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(244, 217, 255, 0.66) 0.52%,
    rgba(255, 217, 242, 0.66) 24.02%,
    rgba(201, 211, 255, 0.66) 72.81%
  );
  margin-bottom: 16px;
  border-radius: 10px;
  padding: 20px 26px;

  .Workspace_stats_element {
    width: 260px;
    background-color: white;
    padding: 22px 16px;
    padding-top: 8px;
    text-align: center;
    margin: 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .Workspace_stats_element_title_wrapper {
    display: flex;
    align-items: center;
    .Workspace_stats_element_title {
      font-size: 12px;
      font-weight: bold;
      margin-left: 8px;
      text-align: left;
    }
    .Workspace_stats_element_icon {
      font-size: 18px;
    }
  }
  .Workspace_stats_element_content_wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Workspace_stats_element_content {
    font-size: 38px;
    font-weight: 100;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Workspace_stats_element_content_sub {
    color: #c0c0c0;
  }
}

@media only screen and (max-width: 950px) {
  .PageWrapper {
    .Filters {
      flex-wrap: wrap;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .Workspace_stats_inner {
    flex-wrap: wrap;
  }
}

.DeleteColumn {
  padding: 0 !important;
}
