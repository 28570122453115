.KPICard {
  padding: 8px;
  border: 1px solid lightgrey;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  text-align: center;
  width: 150px;
  height: 86px;
  margin: 16px;

  .Number {
    font-weight: bold;
    font-size: 18px;

    .ant-typography-copy {
      font-size: 11px;
      color: lightgrey;
    }
  }

  .SubTitle {
    font-size: 9px;
    font-weight: 200;
    color: grey;
  }
}
